import React from 'react';
import './Footer.css'; // Импортируем стили для Footer

function Footer() {
  return (
    <footer className="footer">
      <p>v2.0.0</p>
    </footer>
  );
}

export default Footer;
