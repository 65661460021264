import React, { useContext, useEffect, useState } from 'react';
import Navbar from './NavBar';
import Sidebar from './SideBar';
import Leaderboard from './Leaderboard';
import Footer from './Footer';
import { AuthContext } from '../context/AuthContext';
import { getPersonalInfoAndHours } from '../api/user';
// import './Dashboard.css';



const Dashboard = () => {
  const { authState, logout } = useContext(AuthContext);
  const { token } = authState;

  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (token) {
      getPersonalInfoAndHours(token)
        .then(setUserInfo)
        .finally(() => setLoading(false));
    }
  }, [token]);

  if (loading) return <div>Loading...</div>;
  if (!userInfo) return <div>No user info</div>;

  return (
    <div className="App">
      <Navbar user={userInfo} logout={logout} />

      <div className="main-content">
        <Sidebar user={userInfo} />
        <Leaderboard />
      </div>

      {/* <Footer /> */}
    </div>
  );
};

export default Dashboard;
