import React from 'react';
import './UserCard.css';

function UserCard({ user }) {
  return (
    <div className="user-card">
      <h2 className="login">{user.login}</h2>
      <p className="role">{user.role || 'Undefined developer 😈'}</p>
      <p className="lives">Lives: {user?.lives ? '❤️'.repeat(user.lives) : '💀'}</p>
      <p className="coins">Coins: {user.coins || 0}</p>
      <p className="xp">XP: {user.total_xp || 0}</p>
      <p className="hours">Hours: {user.hours || 0}</p>
    </div>
  );
}

export default UserCard;
