import React, { useState, useEffect, useContext } from 'react';
import './Leaderboard.css';
import { getLeaderboard } from '../api/leaderboard';
import { AuthContext } from '../context/AuthContext'; 

function Leaderboard() {
  const [data, setData] = useState([]);
  const { authState } = useContext(AuthContext);
  const { token } = authState;

  useEffect(() => {
    if (token) {
      fetchLeaderboardData(token);
    }
  }, [token]);

  const fetchLeaderboardData = async (token) => {
    try {
      const leaderboardData = await getLeaderboard(token);

      const sortedData = leaderboardData.sort((a, b) => b.total_xp - a.total_xp);

      setData(sortedData);
    } catch (error) {
      console.error('Error fetching leaderboard data:', error);
    }
  };

  return (
    <div className="leaderboard">
      <div className="leaderboard-header">
        <h2>Leaderboard</h2>
      </div>

      <table>
        <thead>
          <tr>
            <th>Rank</th>
            <th>Login</th>
            <th>Cohort</th>
            <th>XP</th>
            <th>Role</th>
          </tr>
        </thead>
        <tbody>
          {data.map((user, index) => (
            <tr key={user.login}>
              <td>{index + 1}</td>
              <td>{user.login}</td>
              <td>{user.cohort}</td>
              <td>{user.total_xp}</td>
              <td>{user.role}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Leaderboard;
