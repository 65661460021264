import React, { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import './LoginForm.css';

const GITEA_URL = process.env.REACT_APP_GITEA_URL;
const GITEA_AUTH_CLIENT_ID = process.env.REACT_APP_GITEA_AUTH_CLIENT_ID;
const AUTH_REDIRECT_URL = process.env.REACT_APP_AUTH_REDIRECT_URL;
const AUTH_URL = `${GITEA_URL}/login/oauth/authorize?client_id=${GITEA_AUTH_CLIENT_ID}&redirect_uri=${AUTH_REDIRECT_URL}&response_type=code&state=STATE`;
console.log(AUTH_URL);

const LoginForm = () => {
  const { authState } = useContext(AuthContext);

  return authState.token ? (
    <div></div>
  ) : (
    <div className="login-container">
      <a href={AUTH_URL} className="login-link">Войти через Gitea</a>
    </div>
  );
};

export default LoginForm;
