const backend_uri = process.env.REACT_APP_BACKEND_URL
export const getLeaderboard = async (token) => {
    const response = await fetch(`${backend_uri}/api/v1/leaderboard`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  
    if (!response.ok) {
      throw new Error('Failed to fetch leaderboard information');
    }
  
    return await response.json();
  };
  