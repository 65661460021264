import React from 'react';
import './Marketplace.css'; // Импортируем стили для Marketplace
import stamp from '../assets/stamp.svg'; // Импортируем картинку


function Marketplace() {
  return (
    <div className="marketplace">
      <img src={stamp} alt="Stamp" className="stamp" />
      <div className="title">
        <h2>Marketplace</h2>
      </div>
      <div className="coming-soon">
        <p>Coming soon...</p>
      </div>
    </div>
  );
}

export default Marketplace;
