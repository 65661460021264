import React from 'react';
import logo from '../assets/logo.svg';
import './Navbar.css';


function Navbar({ user, logout }) {
  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <img src={logo} alt="Logo" className="logo" />
        <h1>Dashboard</h1>
      </div>

      <div className="navbar-buttons">
        <button onClick={() => alert('Support Clicked')}>Support</button>
        <button onClick={logout}>Logout</button>
      </div>
    </nav>
  );
}

export default Navbar;
